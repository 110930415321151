import dayjs from 'dayjs'

import { cls, formatAspectRatio, getSizeByResolutionAndAspectRatio } from '@/utils'
import { GenerationConfig, GenerationSetting, Size } from '@/types'
import { Badge } from '@/components/ui/badge'
import ButtonCopy from '../copy-button'
import useAmplitude from '@/hooks/useAmplitude'
import { useMemo } from 'react'

interface CreationInfoProps {
  className?: string
  promptClassName?: string
  metaClassName?: string
  createTimeClassName?: string
  input_type?: string
  prompt?: string
  settings?: GenerationSetting
  generationId: string
  createTime: string
  config: GenerationConfig
}

export default function CreationInfo({
  className,
  promptClassName,
  metaClassName,
  createTimeClassName,
  input_type,
  prompt,
  settings,
  generationId,
  config,
  createTime,
}: CreationInfoProps) {
  const { track } = useAmplitude()

  const tagClassName = 'rounded-sm text-text-subdued px-2 py-1 text-body-sm bg-surface-base hover:bg-surface-base/90'

  const fileCount = Math.max(
    1,
    Number(
      input_type === 'image' ? config?.source_images?.filter(Boolean)?.length ?? 1 : input_type === 'video' ? 1 : 0,
    ) || 0,
  )

  const outputSize: Size | null = useMemo(() => {
    if (settings?.resolution && settings?.aspect_ratio) {
      return getSizeByResolutionAndAspectRatio({
        resolution: settings.resolution,
        aspectRatio: settings.aspect_ratio,
      })
    } else if (settings?.width && settings?.height) {
      return { width: settings.width, height: settings.height }
    }
    return null
  }, [settings])

  return (
    <div className={cls('flex flex-col min-h-0', className)}>
      <div className={cls('flex-1 min-h-0 gap-2.5 flex', promptClassName)}>
        <div className='w-5'>
          <ButtonCopy
            className='p-0 size-5 text-icon-subdued'
            text={prompt}
            onCopied={(seed) => {
              track('click:creation:copy-prompt', { creation_id: generationId })
            }}
          />
        </div>
        <div className='h-max flex-1 no-scrollbar text-text'>
          {input_type && ['image', 'video'].includes(input_type) ? (
            <span className='text-text-interactive'>
              {fileCount} {input_type}
              {fileCount > 1 ? 's' : ''}
            </span>
          ) : null}
          {input_type && ['image', 'video'].includes(input_type) && prompt ? <span>&nbsp;+&nbsp;</span> : null}
          {prompt ? <span className='break-all'>{prompt}</span> : null}
        </div>
      </div>
      <div className={cls('flex flex-row flex-wrap gap-2 shrink-0 mt-auto', metaClassName)}>
        {generationId ? (
          <Badge className={tagClassName}>
            id
            <span className='ml-2 break-all md:break-normal'>{generationId}</span>
          </Badge>
        ) : null}
        {settings?.seed ? (
          <Badge className={tagClassName}>
            seed
            <span className='ml-2'>{settings?.seed}</span>
          </Badge>
        ) : null}
        {settings?.aspect_ratio ? (
          <Badge className={tagClassName}>
            ar
            <span className='ml-2'>{formatAspectRatio(settings?.aspect_ratio ?? '--')}</span>
          </Badge>
        ) : null}
        {settings?.motion_level ? (
          <Badge className={tagClassName}>
            motion level
            <span className='ml-2'>{settings?.motion_level === -1 ? 'default' : settings?.motion_level}</span>
          </Badge>
        ) : null}
        {outputSize ? (
          <Badge className={tagClassName}>
            resolution
            <span className='ml-2'>
              {outputSize.width}x{outputSize.height}
            </span>
          </Badge>
        ) : null}
      </div>
      {createTime ? (
        <div className={cls('text-body-sm text-text-subdued', createTimeClassName)}>
          {dayjs(createTime).format('YYYY-MM-DD HH:mm')}
        </div>
      ) : null}
    </div>
  )
}
