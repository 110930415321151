import { deleteJob, upscale, extend } from '@/service/job.service'
import {
  Creation,
  PoNVoid,
  GalleryItemSource,
  ExtendGenerationParams,
  UpscaleGenerationParams,
  CreationModeEnum,
} from '@/types'
import { addQueryParams, getNextExtendDuration, getVideoDuration, utcDate, utcDateTime } from '@/utils'
import { nanoid } from 'nanoid'
import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useDownloadFile from '@/hooks/useDownloadFile'
import useCreation from '@/hooks/useCreation'
import { toast } from '@/components/ui/use-toast'
import { useAtom, useSetAtom } from 'jotai'
import {
  creationDetailsAtom,
  creationInputAtom,
  deletedCreationIdsAtom,
  globalCollectionsAtom,
  jobsRefreshKeyAtom,
  loginDialogOpenAtom,
  unFavouriteGenerationIdsAtom,
} from '@/atoms'
import { postCollectStateToCreationById, postWatermarkFreeUrl } from '@/service/creation.service'
import { useCachedMyProfile } from '@/hooks/useMyProfile'
import useAuth0Auth from '@/hooks/useAuth0Auth'
import useActivePlan from '@/hooks/useActivePlan'
import useAmplitude from '@/hooks/useAmplitude'
import useCredit from '@/hooks/useCredit'
import { cloneDeep } from 'lodash-es'
import { useCachedVideoDuration } from '@/hooks/useVideoDuration'
import { getCollections, saveToCollection } from '@/service/collection.service'
import { useRouter } from 'next/navigation'

export interface UseInteractionsParams {
  source: 'video-detail' | 'creations' | GalleryItemSource
  generationId: string
  initialData?: Creation
  hidden?: boolean
  inDialog?: boolean
  onDelete?: (generationId?: string) => PoNVoid
  onCloseModal?: () => void
  url?: string
}

export interface UseInteractionsResult {
  handleDownload: (watermarkFree: boolean) => Promise<boolean>
  handleDelete: () => PoNVoid
  handleFavorite: () => PoNVoid
  handleExtend: (newPrompt: string) => PoNVoid
  handleUpscale: () => PoNVoid
  setIsPublic: (isPublic: boolean) => PoNVoid
  handleImageDownload: () => PoNVoid
  handleAddCollection: (keys: string[]) => PoNVoid
  handleImage2Video: () => PoNVoid
  handleOpenComment: () => PoNVoid
  handleRepaint: () => PoNVoid
  refreshJobs: () => PoNVoid

  extendDisableMessage?: { title: string; content: ReactElement | string }
  upscaleDisableMessage?: { title: string; content: ReactElement | string }
  repaintDisableMessage?: { title: string; content: ReactElement | string }
  favorited: boolean
  hasAddCollection: boolean
  collectsCount: number
  shareLink?: string
  isPublic: boolean
  isAuthor: boolean
  creation?: Creation
  trackEventParams: Record<string, any>
  nextExtendDuration: number | null
  realDuration: number
}

export default function useInteractions({
  generationId,
  initialData,
  source,
  url,
  hidden,
  onDelete,
  onCloseModal,
  inDialog,
}: UseInteractionsParams): UseInteractionsResult {
  const { track } = useAmplitude()

  const [jobItemRefreshFlag, setJobItemRefreshFlag] = useState('')
  const tmpRefreshFlagRef = useRef(nanoid())
  const { isLogin } = useAuth0Auth()
  const [outerCreationsMap, setOuterCreationsMap] = useAtom(creationDetailsAtom)
  const setGlobalCollectionList = useSetAtom(globalCollectionsAtom)
  const outerCreation = outerCreationsMap[generationId]
  const setLoginDialogOpen = useSetAtom(loginDialogOpenAtom)

  const { data: activePlan } = useActivePlan()
  const canGeneratePrivateVideo = !!activePlan?.allow_private_generation

  const showAuthDialog = useCallback(() => {
    setLoginDialogOpen(true)
  }, [setLoginDialogOpen])

  const { data: profile } = useCachedMyProfile()
  const setUnfavouriteGenerationIds = useSetAtom(unFavouriteGenerationIdsAtom)

  const refresh = useCallback(() => {
    tmpRefreshFlagRef.current = nanoid()
    setTimeout(() => {
      setJobItemRefreshFlag(tmpRefreshFlagRef.current)
    }, 300)
  }, [])

  const shouldUseInitialData = !!hidden || (jobItemRefreshFlag === '' && !!initialData)

  // job item
  const { data: creationRes } = useCreation(shouldUseInitialData ? '' : generationId, jobItemRefreshFlag)
  const creation = creationRes?.data
  const [staleCreation, setStaleCreation] = useState<Creation | null>(shouldUseInitialData ? initialData ?? null : null)
  const { refreshCredit } = useCredit()

  // const [isPublic, setIsPublic] = useState(staleCreation?.is_public ?? true)
  const isPublic = staleCreation?.is_public ?? true
  const setIsPublic = useCallback(
    (value: boolean) => {
      if (staleCreation) {
        setOuterCreationsMap((old) => {
          return {
            ...old,
            [staleCreation?.creation_id]: {
              ...staleCreation,
              is_public: value,
            },
          }
        })
      }
    },
    [setOuterCreationsMap, staleCreation],
  )

  useEffect(() => {
    if (outerCreation) {
      setStaleCreation({
        ...staleCreation,
        ...outerCreation,
      })
    }
  }, [outerCreation])

  useEffect(() => {
    // TODO: has a latency issue, need to fix. If user closes the modal before the creation is fetched, the creation's last state will not be updated on the list page.
    if (creation) {
      setStaleCreation(creation)
      setOuterCreationsMap((old) => {
        return {
          ...old,
          [creation.creation_id]: creation,
        }
      })
    }
  }, [creation, setOuterCreationsMap])

  const [innerFavorited, setInnerFavorited] = useState<boolean | null>(null)
  const [innerHasAddCollection, setInnerHasAddCollection] = useState<boolean | null>(null)
  const [innerCollectsCount, setInnerCollectsCount] = useState<number>(0)

  const filename = useMemo(() => {
    if (!staleCreation) return 'video.mp4'
    return `${staleCreation.creation_id ?? 'video'}.mp4`
  }, [staleCreation])

  const { download } = useDownloadFile({
    url,
    name: filename,
  })

  const hasWatermarkFreeUrl = !!staleCreation?.is_watermark_free_enabled

  const isAuthor = profile?.user_id === staleCreation?.user_id

  const trackEventParams = useMemo(() => {
    return {
      creation_id: generationId,
      source,
      is_author: isAuthor,
      create_time: initialData?.create_time ? utcDateTime(initialData?.create_time) : null,
      create_date: initialData?.create_time ? utcDate(initialData?.create_time) : null,
    }
  }, [source, isAuthor, generationId, initialData])

  const collected = staleCreation?.commits?.is_collect ?? false
  const hasAddCollection = staleCreation?.is_collected ?? false
  const collectsCount = staleCreation?.commits?.collects_count ?? 0

  const setCreationInput = useSetAtom(creationInputAtom)

  const router = useRouter()

  const gotoCreations = useCallback(() => {
    if (inDialog) {
      router.back()
      onCloseModal?.()
    }
    setTimeout(() => {
      router.push('/creations')
    }, 0)
  }, [router, inDialog, onCloseModal])

  const setJobsRefreshKey = useSetAtom(jobsRefreshKeyAtom)

  const refreshJobs = useCallback(() => {
    setJobsRefreshKey(nanoid())
    gotoCreations()
  }, [setJobsRefreshKey, gotoCreations])

  const handleImage2Video = useCallback(() => {
    if (initialData) {
      const url = initialData.output_url
      setCreationInput((prev) => ({
        ...prev,
        mode: CreationModeEnum.AnimateHD,
        creation: {
          ...initialData,
          settings: { ...initialData.settings, resolution: 720 },
        },
        expanded: true,
        focusing: true,
        img: url,
      }))
      gotoCreations()
    }
  }, [initialData, setCreationInput, gotoCreations])

  const handleRepaint = useCallback(() => {
    track('click:creation:repaint', {
      creation_id: generationId,
    })
    setCreationInput((prev) => ({
      ...prev,
      mode: CreationModeEnum.Repaint,
      creation: initialData,
      expanded: true,
      focusing: true,
    }))
    gotoCreations()
  }, [generationId, initialData, setCreationInput, track, gotoCreations])

  // settingsDuration is not accurate, use videoDuration instead. For example, inpainting jobs passes 4 as duration, but the real duration is 2, and returned settings.duration is 8.
  const settingsDuration = staleCreation?.type === 'generation' ? staleCreation?.settings?.duration ?? 0 : 0

  // const { data: videoDuration, isValidating: videoDurationLoading } = useCachedVideoDuration(settingsDuration ? '' : staleCreation?.video_url)
  const videoUrlWithStatus = useMemo(() => {
    if (!staleCreation?.video_url) {
      return ''
    }
    return addQueryParams(staleCreation?.video_url, {
      status: staleCreation?.status,
    })
  }, [staleCreation?.video_url, staleCreation?.status])

  // we only need video duration for current user's video, and only when settingsDuration is not available
  // videoDuration is used for extend duration calculation and credit deduction
  const shouldFetchDurationFromVideoUrl = !settingsDuration && isAuthor && source === 'creations'

  const { data: videoDuration, loading: videoDurationLoading } = useCachedVideoDuration(
    shouldFetchDurationFromVideoUrl ? videoUrlWithStatus : '',
  )

  const realDuration = Math.floor(videoDuration || settingsDuration || 0)

  const nextExtendDuration = useMemo(() => {
    if (staleCreation?.type === 'extend') {
      return 0
    }
    const defaultExtendDuration = 4
    if (!realDuration || videoDurationLoading) {
      return defaultExtendDuration
    }
    return getNextExtendDuration(realDuration)
  }, [realDuration, videoDurationLoading, staleCreation])

  useEffect(() => {
    setInnerFavorited(collected)
  }, [collected])

  useEffect(() => {
    setInnerHasAddCollection(hasAddCollection)
  }, [hasAddCollection])

  useEffect(() => {
    setInnerCollectsCount(collectsCount)
  }, [collectsCount])

  const statesRef = useRef({
    collected: innerFavorited,
  })

  useEffect(() => {
    statesRef.current = {
      collected: innerFavorited,
    }
  }, [innerFavorited])

  const handleImageDownload = useCallback(async () => {
    track('click:creation:image:download', {
      image_id: initialData?.output_id,
      image_url: initialData?.output_url,
    })
    const response = await fetch(initialData?.output_url || '')
    const blob = await response.blob()
    const urlBlob = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = urlBlob
    a.download = 'image.png'
    document.body.appendChild(a)
    a.click()
    URL.revokeObjectURL(urlBlob)
    document.body.removeChild(a)
  }, [initialData?.output_id, initialData?.output_url, track])

  const handleDownload = useCallback(
    async (watermarkFree = false) => {
      if (!isLogin) {
        showAuthDialog()
        return false
      }

      track('click:creation:download', {
        ...trackEventParams,
        watermark_free: watermarkFree,
        has_watermark_free_url: hasWatermarkFreeUrl,
      })

      if (watermarkFree) {
        const url = (await postWatermarkFreeUrl(generationId))?.url
        await download(url)
      } else {
        await download()
      }
      return true
    },
    [download, isLogin, showAuthDialog, generationId, track, trackEventParams, hasWatermarkFreeUrl],
  )

  const setDeletedCreationIds = useSetAtom(deletedCreationIdsAtom)
  const handleDelete = useCallback(async () => {
    if (!isLogin) {
      showAuthDialog()
      return
    }
    await deleteJob(generationId)
    setDeletedCreationIds((prev) => {
      return [...prev, generationId]
    })
    toast({
      title: 'Video has been deleted',
      color: 'success',
    })
    onDelete?.(generationId)
  }, [isLogin, generationId, showAuthDialog, onDelete, setDeletedCreationIds])

  const handleAddCollection = useCallback(
    async (keys: string[]) => {
      track('click:creation:addToCollection', {
        creationId: staleCreation?.creation_id,
        collectionIds: keys,
      })
      setInnerHasAddCollection(keys.length > 0)
      try {
        await saveToCollection({
          collection_ids: keys,
          creation_id: staleCreation?.creation_id || '',
        })
        const res = await getCollections()
        if (staleCreation) {
          setOuterCreationsMap((prev) => ({
            ...prev,
            [staleCreation.creation_id]: {
              ...staleCreation,
              is_collected: keys.length > 0,
            },
          }))
        }
        setGlobalCollectionList(res)
      } catch (error) {
        setInnerHasAddCollection(!!staleCreation?.is_collected)
      }
    },
    [track, staleCreation, setGlobalCollectionList, setOuterCreationsMap],
  )

  const handleFavorite = useCallback(async () => {
    if (!isLogin) {
      showAuthDialog()
      return
    }
    const newState = !statesRef.current.collected
    if (newState) {
      track('click:creation:favorite', trackEventParams)
    }
    setInnerFavorited(newState)
    setInnerCollectsCount((old) => {
      return Math.max(0, old + (newState ? 1 : -1))
    })

    setUnfavouriteGenerationIds((old) => {
      return newState ? old.filter((id) => id !== generationId) : [...old, generationId]
    })
    try {
      await postCollectStateToCreationById(
        {
          output_id: initialData?.output_id ?? generationId,
          is_collect: newState,
        },
        'v2',
      )
    } catch (error) {
      setInnerFavorited(!newState)
    }
    // refresh()
  }, [
    initialData,
    generationId,
    refresh,
    setUnfavouriteGenerationIds,
    isLogin,
    showAuthDialog,
    track,
    trackEventParams,
  ])

  const originalVideoId = useMemo(() => {
    return staleCreation?.output_video ?? ''
  }, [staleCreation])

  const handleExtend = useCallback(
    async (newPrompt: string) => {
      track('click:creation:extend', trackEventParams)
      const params: ExtendGenerationParams = cloneDeep({
        prompt: newPrompt ?? '',
        is_public: isPublic || !canGeneratePrivateVideo,
        parent_id: staleCreation?.creation_id ?? '',
        config: {
          extend_duration: nextExtendDuration,
          source_video: originalVideoId,
        },
      })
      if (refreshCredit) {
        await refreshCredit()
      }
      await extend(params)
      refreshJobs()
      gotoCreations()
    },
    [
      isPublic,
      canGeneratePrivateVideo,
      track,
      originalVideoId,
      nextExtendDuration,
      trackEventParams,
      refreshCredit,
      staleCreation,
      refreshJobs,
      gotoCreations,
    ],
  )

  const handleUpscale = useCallback(async () => {
    track('click:creation:upscale', trackEventParams)
    const videoDuration = await getVideoDuration(staleCreation?.video_url ?? '')

    const params: UpscaleGenerationParams = cloneDeep({
      prompt: staleCreation?.prompt ?? '',
      is_public: isPublic || !canGeneratePrivateVideo,
      parent_id: staleCreation?.creation_id ?? '',
      config: {
        source_video: originalVideoId,
      },
      settings: {
        duration: videoDuration,
      },
    })
    await upscale(params)
    if (refreshCredit) {
      await refreshCredit()
    }
    refreshJobs()
    gotoCreations()
  }, [
    isPublic,
    originalVideoId,
    canGeneratePrivateVideo,
    track,
    trackEventParams,
    refreshCredit,
    staleCreation,
    refreshJobs,
    gotoCreations,
  ])

  const extendDisableMessage = useMemo(() => {
    if (videoDurationLoading) {
      return {
        title: '',
        content: 'Loading',
      }
    }
    if (staleCreation?.type === 'extend') {
      return {
        title: '',
        content: 'You already extended this video',
      }
    }
    if (staleCreation?.type === 'upscale') {
      return {
        title: '',
        content: 'Unavailable for enhanced video',
      }
    }
  }, [staleCreation?.type, videoDurationLoading])

  const upscaleDisableMessage = useMemo(() => {
    if (videoDurationLoading) {
      return {
        title: '',
        content: 'Loading',
      }
    }
    if (staleCreation?.type === 'upscale') {
      return {
        title: '',
        content: 'You already enhanced this video',
      }
    }
  }, [staleCreation?.type, videoDurationLoading])

  const repaintDisableMessage = useMemo(() => {
    if (staleCreation?.type === 'extend') {
      return {
        title: '',
        content: 'Unavailable for extended video',
      }
    }
    if (staleCreation?.type === 'upscale') {
      return {
        title: '',
        content: 'Unavailable for enhanced video',
      }
    }
  }, [staleCreation?.type])

  const handleOpenComment = useCallback(() => {
    let url = `/creation/${generationId}`
    // if (initialData?.output_id !== generationId) {
    //   url += `?output_id=${initialData?.output_id}`
    // }
    if (initialData?.output_type === 'image') {
      url += '?type=output'
    }
    router.push(url)
  }, [generationId, initialData, router])

  return {
    handleDownload,
    handleDelete,
    handleFavorite,
    handleAddCollection,
    handleImage2Video,
    handleExtend,
    handleRepaint,
    handleOpenComment,
    handleUpscale,
    handleImageDownload,
    refreshJobs,
    isPublic,
    isAuthor,
    setIsPublic,
    favorited: innerFavorited ?? false,
    hasAddCollection: innerHasAddCollection ?? false,
    collectsCount: innerCollectsCount ?? 0,
    shareLink: `${location.origin}/creation/${generationId}`,
    creation: staleCreation ?? undefined,
    extendDisableMessage,
    upscaleDisableMessage,
    repaintDisableMessage,
    realDuration,
    nextExtendDuration,
    trackEventParams,
  }
}
